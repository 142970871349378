<template>
  <div class="view-overlay">
    <div v-if="view === 'reset'" class="mx-4 mt-4">
      <div class="step-top-banner rounded overflow-hidden">
        <img :src="require('@/assets/img/Auth/password.svg')" alt="" />
      </div>
      <form @submit.prevent="setNewPassword()" class="pt-8 mx-3">
        <input
          type="password"
          class="w-100"
          placeholder="New password"
          autocomplete="no"
          v-model="newPassword"
        />
        <p class="input-tooltip">Minimum 6 characters</p>
        <button
          :class="{ faded: !newPassword }"
          class="btn btn-black btn-block mt-6"
          type="submit"
        >
          Continue
        </button>
      </form>
    </div>
    <div v-if="view === 'confirmed'" class="mx-4 mt-4">
      <div class="step-top-banner rounded overflow-hidden">
        <img :src="require('@/assets/img/Confirm/welcome.svg')" alt="" />
      </div>
      <div class="text-center pt-8 mx-3">
        <h2 class="bold">You are now verified, happy days.</h2>
        <p class="mt-5">
          This means we will send you great content on your favorite topics.
        </p>
        <a href="https://gokind.app">
          <button class="btn btn-black btn-block mt-6">Continue</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { db, UPDATE, firebase } from "@/firebase.js";
export default {
  name: "Confirm",
  data: function () {
    return {
      loaded: false,
      view: null,
      newPassword: null,
    };
  },
  methods: {
    setNewPassword: function () {
      if (!this.newPassword) return;
      console.log("setting new password");
      firebase
        .auth()
        .confirmPasswordReset(this.$route.query.oobCode, this.newPassword)
        .then(() => {
          this.$router.replace({
            path: "/armada",
          });
        });
    },
    resetPassword: function () {
      console.log("need to reset password");
      firebase
        .auth()
        .verifyPasswordResetCode(this.$route.query.oobCode)
        .then(() => {
          this.view = "reset";
        });
    },
    verifyUser: function () {
      if (this.$route.query.mode === "resetPassword") {
        return this.resetPassword();
      }
      let urlParts = this.$route.query.continueUrl.split("=");
      let userId = urlParts[1].split("&")[0];
      let redirect = urlParts[3];
      if (redirect) {
        let alreadySignedUp = urlParts[4];
        let email = urlParts[2].split("&")[0];
        if (alreadySignedUp) {
          window.location =
            "https://gokind.co/tailor?confid=" + userId + "&appemail=" + email;
        } else {
          window.location =
            "https://gokind.co/confirm?confid=" + userId + "&email=" + email;
        }
      } else {
        this.view = "confirmed";
        this.loaded = true;
        console.log(userId);
        let doc = db.collection("users").doc(userId);
        UPDATE(doc, {
          verified: true,
        });
      }
    },
  },
  created: function () {
    this.verifyUser();
  },
};
</script>
